.knot-container {
  width: 100%;
  height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--text-color);
  font-family: 'Inter', sans-serif;
  color: var(--bg);
  background-color: var(--text-color);
  margin: 0;
  padding: 0;
}

.knot-container h3 {
    font-size: 3rem;
    margin: 0;
}

.upper-title,
.lower-title {
  width: 90%;
  text-align: center;
  margin: 0.5em 0; /* Add vertical spacing */
}

.upper-title h3,
.lower-title h3 {
  font-size: 1.5em;
  text-transform: uppercase;
}

svg {
  width: 100%; 
  height: auto; /* Let height adjust naturally based on content */
  display: block; /* Ensure it behaves like a block element */
  margin: 0 auto; /* Center the SVG horizontally */
}

.main-path {
  stroke: var(--primary-green);
}

.skip-button {
  position: absolute;
  top: 100px;
  right: 20px;
  padding: 10px 20px;
  color: var(--text-color);
  background-color: var(--bg);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 10;
  text-transform: uppercase;
  font-weight: 800;
}

.skip-button:hover {
  background-color: var(--primary-green);
  
}

@media (max-width: 768px) {
  .knot-container {
    height: 95vh; /* Adjust height for mobile */
  }

  .upper-title {
    margin-top: 1em; /* Adjust margin to push the SVG down */
  }

  #h3-6 {
    font-size: 1rem;
  }
}