/* General footer styling */
.footer {
  background-color: var(--bg);
  color: var(--text-color);
  text-align: center;
  padding: 30px 0;
  font-family: 'Arial', sans-serif;
  width: 100%;
  box-sizing: border-box;
  border-top: 2px solid var(--text-color);
}

.footer-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 0;
}

/* Footer sections */
.footer-section {
  margin-bottom: 20px;
  max-width: 300px;
}

.footer-section h4 {
  font-size: 1.5rem;
  color: var(--text-color);
  margin-bottom: 15px;
}

.footer-section p, .footer-section ul {
  font-size: 0.9rem;
  line-height: 1.5;
  color: var(--text-color);
}

/* Quick Links section */
.quick-links ul {
  list-style-type: none;
  padding: 0;
}

.quick-links ul li {
  margin-bottom: 10px;
}

.quick-links ul li a {
  color: var(--text-color);
  text-decoration: none;
  transition: color 0.3s ease;
}

.quick-links ul li a:hover {
  color: var(--text-color);
}

/* Contact section */
.contact a {
  color: var(--primary-green);
  text-decoration: none;
  transition: color 0.3s ease;
}

.contact a:hover {
  color: var(--primary-green);
}

/* Bottom section */
.footer-bottom {
  border-top: 1px solid #444;
  padding-top: 10px;
  font-size: 0.85rem;
}

.footer-links {
  margin-top: 10px;
}

.footer-links a {
  color: var(--primary-green);
  margin: 0 15px;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: var(--primary-green);
}

/* Mobile-specific styling */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
  }

  .footer-section {
    margin-bottom: 20px;
    width: 100%;
  }

  .footer-links {
    margin: 15px 0;
  }

  .footer-section ul {
    padding-left: 0;
    list-style-type: none;
  }

  .footer-section ul li {
    text-align: center;
  }
}