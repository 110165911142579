.animation-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  margin-top: 0;
  padding-top: 0;
  
}

.animation {
  display: flex;
  width: 100%;
  overflow: hidden;
}

.circle-wrapper {
  display: flex;margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden; /* Prevent horizontal overflow */
  justify-content: space-between;
  width: 100%; /* Ensure consistent width */
  height: 100%;
  position: relative;
  margin: 0 auto;
  overflow: hidden;
}

.circle {
  width: 8vw;
  height: 8vw;
  border: 0.4vw solid #FAF9F6;
  border-radius: 50%;
  background-color: transparent;
  opacity: 1; /* Keep opacity at 1 for visibility */
  position: relative;
  flex-shrink: 0; /* Prevent circles from shrinking */
}

.dot {
  position: absolute;
  width: 2vw;
  height: 2vw;
  margin: 0;
  background-color: #145214;
  border-radius: 50%;
  opacity: 0;
  z-index: 10;
}

#line-container {
  position: absolute;
  width: 100%; /* Ensure consistent width */
  height: 100%; /* Ensure height is consistent */
  z-index: 5;
  left: 0;
  top: 0;
}


#line-container line {
  stroke: #FAF9F6; /* or any color that suits your design */
  stroke-width: 0.4vw; /* Adjust width based on your design */
  opacity: 0; 
  stroke-linecap: round; /* Optional: gives the line rounded ends */
  
}


.hidden-text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 3.5vh;
  color: #FAF9F6;
  text-shadow: 1px 1px 2px #1D1B26;
  opacity: 0;
  font-family: "Inter";
  z-index: 5;
  margin: 0;
  text-align: center;
  max-width: 600px;

}

@media (max-width: 768px) {
  .animation-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80%;
    position: relative;
    padding-top: 0;
  }

  .animation {
    height: 100%;
  }

  .circle-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    width: 100%; /* Match this with the circle width */
    height: 80%;
    left: 50%;
    transform: translateX(-50%); /* Center horizontally */
  }

  #line-container {
    position: absolute;
    width: 100%; /* Match this with the circle width */
    z-index: 5;
  }
  #line-container line {
    z-index: 6;
  }

  .circle {
    width: 12vw; /* Adjust size for mobile */
    height: 12vw; /* Adjust size for mobile */
    border: 2px solid white; /* Add a visible border */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dot {
    width: 5vw; /* Adjust size for mobile */
    height: 5vw;
    border-radius: 50%; /* Ensure it's a perfect circle */
    background-color: var(--primary-green);
    position: absolute; /* Ensure it's in the same positioning context */
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
    z-index: 10;
  }

  #sights-studios {
    font-size: 3vh;
    text-align: center; /* Align the text to the left */
    position: absolute;
    top: 50%;
    left: 50%; /* Align it to the left edge of the container */
    transform: translateY(-50%, -50%); /* Center vertically */
    opacity: 0; /* Initially hidden */
    width: 100%;
  }

  .scroll-arrow {
    font-size: 5vh;
    text-align: center;
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);
    animation: bounce 1.5s infinite ease-in-out;
    transition: transform 1s ease; /* Smooth transition for the move */
    opacity: 0;
    display: none;
    color: var(--primary-green);
  }
  
  @keyframes bounce {
    0%, 100% {
      transform: translate(-50%, 0);
    }
    50% {
      transform: translate(-50%, -15px);
    }
  }
  
}