.feature-section {
  padding: 50px;
  text-align: center;
  background-color: #2d3436;
  background-image: linear-gradient(315deg, #2d3436 0%, #000000 74%);
}

.rotating-word-section {
  font-size: 2.5rem;
  color: var(--text-color);
  text-align: center;
}

.rotating-word-container {
  display: inline-block;
  position: relative;
  width: 220px; /* Fixed width for the container */
  height: 1.2em;
  overflow: visible; /* Ensure the underline is visible */
  vertical-align: bottom;
  text-align: center; /* Center the rotating word within the container */
}

.rotating-word {
  display: inline-block;
  position: relative;
  white-space: nowrap; /* Prevents the word from breaking */
}

.underline {
  position: absolute;
  left: 0;
  bottom: -10px; /* Adjust this value if the underline needs to move up or down */
  width: 100%; /* Match the width to the content's width */
  max-width: 220px; /* Ensure underline matches the container width */
  height: 5px;
  background-color: #145214; /* Adjust the color as needed */
}

.rotating-word-section .period {
  display: inline-block;
  vertical-align: bottom;
  margin-left: 5px; /* Slight space between the rotating word and the period */
}

.break-mobile {
  display: none; /* Hide the line breaks on desktop */
}

.features {
  display: flex;
  flex-wrap: wrap; /* Wraps the content in case of overflow */
  justify-content: space-around; /* Center items in the container */
  flex-direction: row;
  align-items: stretch; /* Ensures items stretch to fill container height */
  margin-top: 30px;
}

.features a {
  display: block;
  width: 30%;
  text-decoration: none; /* Remove underline from links */
  margin: 10px;
  padding: 0;

}

.feature-card {
  width: 100%;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  transition: transform 0.3s ease, background-size 0.3s ease;
}

.feature-card-inner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end; /* Start with the title at the bottom */
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1); /* Dark overlay for text readability */
  color: white;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
}

.feature-card-inner h3 {
  margin-bottom: -40px; /* Space the title from the bottom */
  font-size: 1.5rem;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.feature-card-inner p {
  margin: 0;
  opacity: 0;
  transform: translateY(20px);
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  height: 25%;
}

.feature-card:hover .feature-card-inner {
  justify-content: center; /* Center content on hover */
  background-color: rgba(0, 0, 0, 0.5); /* Dark overlay for text readability */
}

.feature-card:hover h3 {
  transform: translateY(-50px); /* Move the title up */
}

.feature-card:hover p {
  opacity: 1;
  transform: translateY(0); /* Move the description into view */
}

@media (max-width: 769px) {
  .features {
    flex-direction: column; /* Arrange cards in a column on mobile */
    justify-content: flex-start;
  }

  .features a {
    width: 100%;
    margin: 10px 0;
  }

  .feature-card {
    height: 250px;
  }
}

@media (max-width: 400px) {
  .rotating-word-section {
    font-size: 2rem; /* Adjust font size for smaller screens */
    text-align: center; /* Align text to the left for better readability on small screens */
  }

  .rotating-word-container {
    display: inline-block;
    width: 220px; /* Fixed width for the container */
  }

  .underline {
    width: 220px; /* Ensure the underline width matches the fixed container width */
  }

  .break-mobile {
    display: inline; /* Show line breaks on mobile */
  }
}