.App {
  text-align: center;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Judson:ital,wght@0,400;0,700;1,400&display=swap');

html, body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden; /* Prevent horizontal overflow */
  display: grid;
  place-items: center;
  background-color: var(--bg);
  color: var(--text-color);
  font-family: "Inter", "Judson", "DM Sans", Arial, sans-serif;
  font-display: swap;

}

.app-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100vw;
}