.carousel-section {
  position: relative;
  width: 100%;
  margin: auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0; /* Add padding to prevent cutoff */
  height: 50vh;
}

.background-image {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  position: relative;
  background-repeat: no-repeat;
}

.background-image::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7); /* Slight dark overlay */
  z-index: 1;
}

/* Active card information for desktop */
.active-card-info {
  position: absolute;
  bottom: 20px;
  left: 20px;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 15px;
  border-radius: 8px;
  max-width: 30%; /* Adjusted width */
  z-index: 10;
  text-align: left;
  min-height: 30vh; /* Adjusted height */
}

/* Ensure links keep the same color when clicked or visited */
.active-card-info a {
  color: var(--text-color); /* Example color */
  text-decoration: none;
}

.active-card-info a:hover,
.active-card-info a:visited,
.active-card-info a:active {
  color: var(--text-color); /* Keeps the same color */
}

/* Small cards container for desktop */
.small-cards-container {
  position: absolute;
  bottom: 20px;
  right: 20px;
  display: flex;
  gap: 10px;
  justify-content: center;
  z-index: 20;
  padding: 10px;
}

.small-card {
  width: 10vw; /* Proportional width */
  height: 30vh; /* Match the height of the active card */
  border-radius: 8px;
  overflow: hidden;
  transition: border 0.3s ease;
}

.small-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.small-card:hover {
  border: 2px solid var(--primary-green);
}

/* White outline for active small card */
.small-card.active {
  border: 2px solid var(--text-color); /* White outline for the active card */
}

/* Media queries for smaller screens (Mobile) */
@media (max-width: 768px) {
  .active-card-info {
    position: relative;
    bottom: auto;
    left: 50%;
    transform: translateX(-50%);
    max-width: 90%; /* Skinnier width */
    min-height: 400px; /* Taller height */
    text-align: center;
    font-size: 16px;
    margin-bottom: 20px; /* Space between active card info and small cards */
    margin-top: 40px; /* Space between top and active card info */
  }

  .small-cards-container {
    position: relative;
    bottom: auto;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 20px; /* Added more space between active card info and small cards */
    gap: 12px; /* Adjust gap between small cards */
    justify-content: center;
    flex-wrap: wrap;
    padding: 10px;
  }

  .small-card {
    width: 100px; /* Increased width for better readability */
    height: 180px; /* Increased height for visibility */
  }

  .carousel-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 0;
    height: auto;
  }
}

/* Media queries for very small screens (Under 400px) */
@media (max-width: 400px) {
  .small-cards-container {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two columns */
    grid-gap: 15px; /* Space between the cards */
    justify-content: center;
    justify-items: center; /* Center the small cards */

  }

  .small-card {
    width: 100px; /* Adjust width for small screens */
    height: 150px; /* Adjust height for small screens */
  }
}