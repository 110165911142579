.expertise-banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.wrapper {
  width: 100%;
  height: 50px;
  overflow: hidden;
  white-space: nowrap;
  background-color: transparent;
}

.box {
  display: inline-block;
  width: 300px;
  height: 50px;
  background-color: var(--text-color);
  color: var(--bg);
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 50px;
  text-align: center;
  border-radius: 4px;
}

.boxes {
  display: inline-flex;
  gap: 10px;
}
